.mod-high-contrast:not(.mod-skin-dark) {

	.nav-menu li a,
	.nav-title,
	.nav-menu li a [class*='fa-'], 
	.nav-menu li a .#{$cust-icon-prefix},
	.dl-ref,
	.btn {
		text-shadow:
			   -1px -1px 0 $black,  
			    1px -1px 0 $black,
			    -1px 1px 0 $black,
			     1px 1px 0 $black;
		color: $white !important;
		font-weight:500 !important;
	}

	.subheader-title,
	h1,
	h2,
	h3,
	h4,
	h5,
	.settings-panel-title a,
	.panel-header,
	.badge-detached,
	.btn-secondary,
	.btn-default,
	.page-header .btn,
	[class*="btn-outline-"] {
		text-shadow:
			   -1px -1px 0 $white,  
			    1px -1px 0 $white,
			    -1px 1px 0 $white,
			     1px 1px 0 $white;
		color: $black !important;
		font-weight:500;
	}


	.subheader-title small,
	.breadcrumb > li > a,
	.page-content,
	h1 small,
	h2 small,
	h3 small,
	h4 small,
	h5,
	h6,
	p,
	.btn-switch + .onoffswitch-title,
	.onoffswitch-title + .onoffswitch-title-desc,
	.panel-container,
	.panel-header .btn {
		text-shadow: none;
		color: $black !important;
		font-weight: normal !important;
	}

}