.breadcrumb {
    margin: auto;
    background-color: inherit;
}

@media ( max-width: $mobile-breakpoint-size ){
    .breadcrumb {
        display: none;
    }
}
