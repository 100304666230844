// fade
@-webkit-keyframes seconds {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes seconds {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

//delayed entry
@-webkit-keyframes delayed {
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}
@keyframes delayed {
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

@keyframes subtle{
    0% { opacity:1; }
    100% { opacity:0.2; }
}

@-webkit-keyframes subtle{
    0% { opacity:1; }
    100% { opacity:0.2; }
}