.nav-function-fixed {
    .content-wrapper {
        margin-left: $nav-minify-width;
    }

    &:not(.nav-function-minify) {
        .content-wrapper {
            margin-left: $nav-width;
        }
    }

    @media (max-width: $mobile-breakpoint-size) {
        .content-wrapper {
            margin-left: 0;
        }
    }
}


//.content-wrapper {
//    margin-left: $nav-minify-width;
//
//    &:has(.nav-function-minify) {
//        margin-left: $nav-minify-width;
//    }
//
//    &:has(.nav-function-fixed) {
//        margin-left: $nav-width;
//    }
//}
