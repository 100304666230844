// Add anything custom here -- mixins, variable overrides.
// by adding things here instead of overriding existing .LESS files, it makes merging changes on product updates easier

$trackPieceColor:                #0e0e0e;
$handleBar:                      $fusion-100;
$handleBarHover:                 darken($handleBar, 10%);
$modalScrollSize:                6px;

body:not(.mobile-detected) {
  &::-webkit-scrollbar {
    height: $modalScrollSize;
    width: $modalScrollSize;
  }

  &::-webkit-scrollbar:hover{
    background-color:rgba(0,0,0,.01)
  }

  &::-webkit-scrollbar-track-piece {
    background-color: $trackPieceColor;
  }

  &::-webkit-scrollbar-track-piece:hover {
    background-color: darken($trackPieceColor, 12%);
  }

  &::-webkit-scrollbar-thumb {
    background-color: $handleBar;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: $handleBarHover;
  }
}
