#msgr_listfilter_input {
	width: calc(100% - #{$p-3});
	margin: 0 auto;
	margin-top: -41px;
	padding-left: 41px;
	margin-bottom: 10px;
	opacity: 0; 
	background: transparent;
	@extend %general-animation;
	transition-delay: $msgr-animation-delay;  	
}

.msgr-list {
	width: $msgr-list-width;
    right: $msgr-list-width-collapsed - $msgr-list-width;
    z-index: 101;

    @extend %general-animation;
    transition-delay: $msgr-animation-delay;

	& + .msgr {

		width: calc(100% - #{$msgr-list-width-collapsed});
		height: 100%;

		&:before {
			content:'';
			height:100%;
			width:100%;
			background:rgba($white, 0.4);	
			position:absolute;
			z-index: 100;

			@extend %general-animation;
			transition-delay: $msgr-animation-delay;

			opacity: 0;
			visibility: hidden;
		}
	}

    &:hover {
    	right: 0;
    	border-left-color: rgba(0,0,0,0.1);

    	#msgr_listfilter_input {
    		opacity: 1;
    	}

    	& + .msgr {

    		&:before {
    			opacity: 1;
    			visibility: visible;
    		}
    	}
    }
}

/*.msgr-chatinput {

	min-height: 110px;
	max-height: 160px;

	[contenteditable="true"] {
		min-height:50px;
		max-height:110px;
	}

}*/

/*.msgr-chatinput-icons {
	height: 40px;
}*/

/*.msgr-chatinput-container {
	> div {
		border-top: 1px solid rgba($black,0.07);
	}
}*/


/* IE HACK */
/*@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	[contenteditable="true"] {
		height: 110px;
	}
}*/

.chat-segment-get {

	text-align: left;
	position: relative;

	margin: 0 2rem 0.5rem 0;

	&.chat-start {

		.chat-message {
			border-bottom-left-radius: 3px;
		}

		+ :not(.chat-end) {
			.chat-message {
				border-bottom-left-radius: 3px;
				border-top-left-radius: 3px;
			}
		}
	}

	&.chat-end {
		.chat-message {
			border-top-left-radius: 3px;
		}
	}

	.chat-message {
		background: $msgr-get-background;
		@include text-contrast($msgr-get-background);	

		text-align: left;			
	}
}

.chat-segment-sent {

	text-align: right;
	position: relative;

	margin: 0 0 .5rem 3rem;

	&.chat-start {

		.chat-message {
			border-bottom-right-radius: 3px;
		}

		+ :not(.chat-end) {
			.chat-message {
				border-bottom-right-radius: 3px;
				border-top-right-radius: 3px;
			}
		}
	}

	&.chat-end {
		.chat-message {
			border-top-right-radius: 3px;
		}
	}

	.chat-message {
		background: $msgr-sent-background;
		@include text-contrast($msgr-sent-background);

		text-align: left;			
	}

}

.chat-message {
	padding: 0.75rem 1rem;
	border-radius: 0.625rem;

	position: relative;

	display: inline-block;

	> p {
		padding: $p-2 + $p-1 0 0;
		margin:0;
	}

	> p:first-child {
		padding-top: 0;
	}
}

.chat-start {

	margin-bottom: 3px !important;
	
	.time-stamp {
		display: none;
	}

	& + .chat-segment:not(.chat-end) {

		margin-bottom: 3px !important;

		.time-stamp {
			display: none;
		}

	}
}