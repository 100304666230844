.page-sidebar {

	@extend %nav-bg;

	position: relative;
	flex: 1 0 auto;
	width: $nav-width;
	max-width: $nav-width;
    flex-direction: column;
    display: flex;

	z-index: $depth-nav;

	will-change: left, right;
}