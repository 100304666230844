.nav-mobile-slide-out {
	#nmp,
	#nmno {
		@extend %not-compatible;
	}
}

.nav-function-top,
.nav-function-minify,
.mod-hide-nav-icons {
	#mnl {
		@extend %not-compatible;
	}
}

.mod-skin-dark {
	#mhc,
	#mcbg,
	#mdn {
		@extend %not-compatible;
	}
}

@include media-breakpoint-up($mobile-breakpoint-size) {
	.nav-function-top {
		#nfh {
			@extend %not-compatible;
		}
	}
}

@media only screen and (max-width: $mobile-breakpoint-size) {

	.mobile-view-activated {
		#nff,
		#nfm,
		#nfh,
		#nft,
		#mmb {
			  position:relative;
			
			.onoffswitch-title {
				color: #da9400;
				color: $settings-incompat-title !important; 
			}
			.onoffswitch-title-desc {
				color: #da9400;
				color: $settings-incompat-desc !important;
			}
			&:after {
				content: "DISABLED";
				font-size: 10px;
				position: absolute;
				background: $warning-500;
				background: $settings-incompat-bg;
				width: 65px;
				text-align: center;
				border: 1px solid $settings-incompat-border;
				height: 22px;
				line-height: 20px;
				border-radius: $border-radius-plus;
				display: block;
				right: 13px;
				top: 26%;
				color:$fusion-900;
			}
		}
	}
}
