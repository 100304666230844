.popover {
	box-shadow: $dropdown-shadow;
	margin: 12px;

	.arrow {
		border-color: $popover-arrow-outer-color;

	}

	.popover-header {
		font-weight: 500;
		font-size: rem($popover-font-size);
		border-radius: $popover-border-radius $popover-border-radius 0 0;
		border-bottom-width:0px;
	}

	.popover-body {
		padding: 0;
	}
}


.popover-body:not(:empty) {
	padding: 0 $popover-header-padding-x $popover-header-padding-y;
}

.popover-header:empty + .popover-body {
	padding-top: $popover-header-padding-y;
}