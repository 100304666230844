/* set base height for slider */
.slide-on-mobile {
     width: $slider-width;
}

@media only screen and ( max-width: $mobile-breakpoint-size ){
    /* SIDE PANELS */
    .slide-on-mobile {
        @include translate3d(0,0,0);
        z-index: $cloud;
        position: absolute !important;
        top:0;
        bottom:0;

        background-color: $gray-100; 

        transition: $nav-hide-animate;
    }

    .slide-backdrop {
        background: transparent;
        transition: background 300ms;
    }

    .slide-on-mobile-left {
        //@include translate3d(-$slider-width,0,0);  /* issue with translate 3d with padding */
        border-right: 1px solid rgba($black,0.09);

        /* new solution */
        left: -$slider-width;
    }

    .slide-on-mobile-left-show {
        //@include translate3d(0,0,0);
        left:0;
    }

    .slide-on-mobile-right  {
        //@include translate3d(calc(100vw), 0, 0);  /* issue with translate 3d with padding */
        border-left: 1px solid rgba($black,0.09);

        right: -$slider-width;
    }

    .slide-on-mobile-right-show {
        //@include translate3d(calc(100vw - #{$slider-width}), 0, 0);  /* issue with translate 3d with padding */
        right: 0;
    }

    /* place the backdrop right after these classes */
    .slide-on-mobile-right-show,
    .slide-on-mobile-left-show {
        & + .slide-backdrop {
            background: rgba($black,0.09);
            position: absolute;
            z-index: $cloud - 2;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
        }
    }	
}